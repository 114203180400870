import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Footer from "../components/footer";

const Error404Page = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo-dark.png" }) {
        childImageSharp {
          fixed(width: 128) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="flex justify-center h-screen text-white md:items-center bg-blue-dark font-headers">
        <div className="inline-block w-5/6 text-center bg-white border-4 border-white md:py-4 md:rounded-lg lg:w-2/5 2xl:w-1/5">
          <Link to="/">
            <Img fixed={data.logo.childImageSharp.fixed} />
          </Link>

          <h1 className="mt-2 text-2xl font-bold text-gray-700">
            Page not found
          </h1>

          <p className="my-2 font-body text-gray-text">
            No page exists at this address.
          </p>

          <Link to="/" className="text-lg text-blue-light">
            Back to home
          </Link>
        </div>
      </div>

      <div className="fixed bottom-0 w-full">
        <Footer />
      </div>
    </>
  );
};

export default Error404Page;
